import React from "react"
import promo from "../../src/video/rf-workshop.mp4"
import Hero from "../components/hero"
import Layout from '../components/layout-default'
import Banner from "../components/banner"
import { Link } from "gatsby"
import EventBlocks from "../components/event" 
import '../styles/workshops.scss'
import Omar from "../images/OmarWorkshop.jpg"
// import { node } from "prop-types";

const Workshops = ({ data }) => (
  <Layout
    title="Salsa &amp; Bachata Workshops in Orange County | RF Dance"
    description="OC's Homegrown latin dance teams." 
    pathname="workshops"
    img={Omar}>
    <Hero 
      titleRed="RF"
      titleWhite="Workshops"
      subtitle="Take your dancing to the next level"
      promo={promo} />
      
      <section className="content">
        <div className="content-block text-center">
          <div className="container">
            <h2>It's time to take your dancing more seriously.</h2>
            <p>Whether it's your first time joining a dance team, or if you're a seasoned veteran - it will be an exciting, challenging, and enjoyable season.  We aren't just your typical dance team, we are a family.  We invest in you, the team, and the experience.  Every year we try to strengthen our dance company by investing in each in and every dancer's experience.</p>
          </div>
        </div>

        <hr className="divider" />

        <div className="content-block">
          <div className="events">
            <EventBlocks cat="workshops"/>
            {/* <p className="has-text-centered">Come back later for workshop updates.</p> */}
          </div>
        </div>
      </section>
      
    <Banner>
      <h2>Looking for more commitment?<br />Join an RF Dance Team.</h2>
      <div>
        <div>
          <h3>Find an RF Dance team.</h3>
          <p><Link to="/teams" className="button is-rounded is-info">RF Dance Teams</Link></p>
        </div>
      </div>
    </Banner>
  </Layout>
)

export default Workshops