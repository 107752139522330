import React from "react"
import contactStyles from "./contact.module.scss"

export default ({ children }) => (
  <div id="banner">
    <div className={`${contactStyles.hero} ${contactStyles.heroSecondary}`}>
      <div className="container">
        <div className={contactStyles.heroContent}>
          {children}
        </div>
      </div>
    </div>
  </div>
)