import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Image = props => {
  // console.log('From image: ', props)
  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        nodes {
          ... on ImageSharp {
            resize(width:800) {
              src
              originalName
            }
          }
        }
      }
    }
  `)

  const sourceArray = data.allImageSharp.nodes.map(node => {
    if (node.resize.originalName === props.src) {
      
      console.log('original name: ', node.resize.src)
      return node.resize.src;
    } else return ` `;
  });

  const imageSource = sourceArray.filter(item => {
    return item.length > 1
  })

  return <img src={imageSource} alt={props.alt} />
}

export default Image