import React from 'react'
import styles from '../components/event.module.scss'
import { StaticQuery, graphql } from 'gatsby'
import Image from '../components/image'
// const staticData = require('../static/events.json')
class Event extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...props,
      expired: 'valid'
    }
  }

  componentDidMount() {
    if (this.state.date_expired) {
      this.expiry = this.eventExpired(this.state.date_expired)
      if (this.expiry !== undefined) {
        this.setState({ expiry: this.expiry })
      }
    }
  }

  eventExpired(date) {
    const today = new Date()
    const eventDate = new Date(date)
    if (eventDate < today) {
      return 'expired'
    } else {
      return 'valid'
    }
  }

  render() {
    if (this.state.expiry === 'expired') {
      return (
        <div style={({'display':`none`})}></div>
      )
    } else if (this.state.cat && this.state.category !== this.state.cat ) {
      return (
        <div style={({'display':`none`})}></div>
      )
    } else if (this.state.plain) {
      return (
        <div className={`${styles.event} ${this.state.date_expired}`}>
          <div className={`${styles.mobileCol} ${styles.imgCol}`}>
            <a target="_blank" rel="noopener noreferrer" href={this.state.link} title={this.state.title}>
              <Image 
                src={(this.state.image ? this.state.image : `logo.jpg`)} 
                alt={(this.state.event_description? this.state.event_description : `RF Dance Event`)} />
            </a>
          </div>
        </div>
      )
    } else {
      let cta = ''

      if (this.state.event_link) {
        cta = <div className={styles.eventLinks}><a href={this.state.event_link} className="button is-info is-rounded">{(this.state.linkText ? this.state.linkText : `Buy Tickets`)}</a></div>
      } else if (this.state.plain) {
        cta = ``
      } else {
        cta = <div style={({'marginTop':'0.75em'})}><em>Coming soon</em></div>
      }

      return (
        <div className={`${styles.event} ${this.state.date_expired}`}>
          
          <div className={`${styles.mobileCol} ${styles.imgCol}`}>
            <a target="_blank" rel="noopener noreferrer" href={this.state.link} title={this.state.title}>
              <Image 
                src={(this.state.image ? this.state.image : `logo.jpg`)} 
                alt={(this.state.event_description? this.state.event_description : `RF Dance Event`)} />
            </a>
          </div>

          <div className={`${styles.mobileCol}`}>
            <p 
              className="eyebrow" 
              style={({"textTransform":"uppercase","padding":"0 1.3em", "marginBottom":"0", "fontSize":"0.8em"})}>{(this.state.title_eyebrow? `${this.state.title_eyebrow}` : `RF Event`)}
            </p>
            <h3>{this.state.event_name}</h3>
            <h4>{(this.state.presale_price ? `Buy Pre-Sale Tickets Online` : `Register online`)}</h4>
            {(this.state.event_description ? <p className={styles.description}>{this.state.event_description}</p> : '')}
            <p><strong>Location: </strong>RF Dance Studio</p>
            {(this.state.event_date ? <p><strong>Day: </strong>{this.state.event_date}</p> : '')}
            {(this.state.start_time ? <p><strong>Time: </strong>{`${this.state.start_time}`}</p> : '')}
            {cta}
          </div>
        </div>
      )
    }
  }
}


export default props => (
  <StaticQuery
    query={graphql`
      query {
        allEvents(sort: {fields: event_date}) {
          nodes {
            image
            event_date
            start_time
            end_time
            event_link
            event_name
            date_expired
            door_price
            presale_price
            location
            date_active
            title_eyebrow
            event_description
            category
            plain
          }
        }
      }
    `
    }
    
    render={data => {
      const eventNodes = data.allEvents.nodes.map((event, i) => (
        <Event key={i} {...event} {...props}></Event>
      ));
      
      return eventNodes;
    }}
  />
)